import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Notification } from '../dto/notification';
import { NotificationHttpService } from '../http/notification.http';

interface NotificationState {
  notifications: Notification[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationStateService extends StateService<NotificationState> {
  readonly notifications$: Observable<Notification[]> = this.select((state) => state.notifications);

  constructor(
    private readonly notificationHttpService: NotificationHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshNotifications(company: Company): Promise<void> {
    try {
      const notifications: Notification[] = await lastValueFrom(
        this.notificationHttpService.getNotifications(company.id),
      );
      this.setState({ notifications });
    } catch (e: any) {
      this.logger.error(e);
    }
  }
}
