import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Notification } from '../dto/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationHttpService {
  constructor(private readonly http: HttpClient) {}

  getNotifications(companyId: number): Observable<Notification[]> {
    return this.http.get<Notification[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/customer-todo`,
    );
  }
}
