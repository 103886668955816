import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutingService, URL } from '@dougs/core/routing';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class CanAccessNotificationGuard {
  constructor(
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly userStateService: UserStateService,
    private readonly routingService: RoutingService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.companyServicesStateService.services$.pipe(
      map((services) => {
        if (services?.features.hasNotification) {
          return true;
        }

        return this.routingService.createUrl([URL.ACCOUNTING]);
      }),
    );
  }
}
